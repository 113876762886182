import { AgencyModel } from '../assets/models/agencies/Agency.model';
import { callable } from './httpWrapper.service';
import { MandateModel } from '../assets/models/mandates/mandate.model';
import { RedirectUrlResponse } from '../assets/responses/RedirectUrl.response';
import { AgencyBankingAliasModel } from '../assets/models/payments/PaymentBankingAlias.model';
import { ConfirmationResponse } from '@assets/responses/Confirmation.response';
import { UpdateAgencyRestitutionRequest } from '@assets/requests/agencies/UpdateAgencyRestitution.request';
import { BeneficiaryClassificationModel } from '@assets/models/beneficiaries/BeneficiaryClassification.model';
import {
  convertBeneficiaryClassificationsFromCentsToEuros,
} from '../assets/utils/agencies/beneficiaryClassification.util';
import { getAllManagementUnitsSettingsOfAgencyAdmin } from '../Services/agencyAdminAPI.service';
import {
  AgencyAdminManagementUnitSettingModel,
  AgencyAdminSettingModel,
} from '../assets/models/agencyAdmin/AgencyAdmin.model';
import { ManagementUnitModel } from '../assets/models/agencies/ManagementUnit.model';
import {
  transformAgencyAdminManagementUnitSettingToManagementUnitModel,
} from '../assets/utils/agencies/managementUnit.util';
import { OrganizationInformationsModel } from '@assets/models/organizationInformations/OrganizationInformations.model';
import { getAllDocs, getDocsWhere, onCall } from '../Services/firebase.service';
import { ContractedBusinessModel } from '@assets/models/discreteVouchers/ContractedBusiness.model';
import { BeneficiaryModel } from '@assets/models/beneficiaries/Beneficiary.model';
import { UpdateAgencyPaymentRequest } from '@assets/requests/agencies/UpdateAgencyPayment.request';

export async function getAgencyByAgencyId(agencyId: string): Promise<AgencyModel | null> {
  return callable(
    'getOneAgency',
    { agencyId },
  );
}

export async function getAllAgenciesOfAgencyAdmin(companyId: string, agencyAdminId: string): Promise<AgencyModel[]> {
  return callable(
    'getAllAgenciesOfAgencyAdmin',
    { companyId, agencyAdminId },
  );
}

export async function getAgencyMandate(agencyId: string): Promise<MandateModel | null> {
  return callable(
    'getAgencyMandate',
    { agencyId },
  );
}

export async function createAgencyMandate(agencyId: string): Promise<RedirectUrlResponse> {
  return callable(
    'createAgencyMandate',
    { agencyId },
  );
}

export async function getAgencyBankAccountAlias(companyId: string, agencyId: string): Promise<AgencyBankingAliasModel> {
  return callable(
    'getAgencyPaymentBankAccountAlias',
    { companyId, agencyId },
  );
}

export async function updateAgencyRestitution(companyId: string, agencyId: string, request: UpdateAgencyRestitutionRequest): Promise<ConfirmationResponse> {
  return callable(
    'updateAgencyRestitution',
    { companyId, agencyId },
    request,
  );
}

export async function updateAgencyPayment(companyId: string, agencyId: string, request: UpdateAgencyPaymentRequest): Promise<ConfirmationResponse> {
  return callable(
    'updateAgencyPayment',
    { companyId, agencyId },
    request,
  );
}

export async function getBeneficiaryClassificationForAgency(companyId: string, agencyId: string): Promise<BeneficiaryClassificationModel[]> {
  return callable(
    'getBeneficiaryClassificationForAgency',
    { companyId, agencyId },
  ).then(convertBeneficiaryClassificationsFromCentsToEuros);
}

export async function getAllAgencyAdminsWithSettingsInformation(companyId: string, agencyId: string): Promise<AgencyAdminSettingModel[]> {
  return onCall('AGENCY_ADMIN-getAllAgencyAdminsWithSettingsInformation_onCall', { companyId, agencyId });
}

export async function getAllAgencyManagementUnits(agencyId: string): Promise<ManagementUnitModel[]> {
  return callable(
    'getAllAgencyManagementUnits',
    { agencyId },
  );
}

export async function getAuthorizedAgencyManagementUnitsOfConnectedAdmin(agencyId: string, agencyAdminId: string): Promise<ManagementUnitModel[]> {
  const adminUnitsSettings: AgencyAdminManagementUnitSettingModel[] = await getAllManagementUnitsSettingsOfAgencyAdmin(agencyId, agencyAdminId);

  return adminUnitsSettings.map(setting => transformAgencyAdminManagementUnitSettingToManagementUnitModel(setting, true));
}

export async function getAgencyOrganizationInformations(agencyId: string): Promise<OrganizationInformationsModel[]> {
  return await getAllDocs(`AGENCIES/${agencyId}/ORGANIZATION_INFORMATIONS`);
}

export async function getAgencyContractedBusinesses(agencyId: string): Promise<ContractedBusinessModel[]> {
  return await getAllDocs(`AGENCIES/${agencyId}/CONTRACTED_BUSINESSES`);
}

export async function getAgencyBeneficiaries(agencyId: string): Promise<BeneficiaryModel[]> {
  return await getDocsWhere<BeneficiaryModel>('BENEFICIARIES', [{
    fieldName: 'agencyId', operator: '==', value: agencyId,
  }]);
}