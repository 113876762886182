import React from 'react';
import clsx from 'clsx';
import { ReactComponent as InfoCircleSvg } from './../assets/svg/message-circle-info.svg';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

export const InfoBox = (props: Props) => {
  const { className, children } = props;

  return (
    <div className={clsx('flex bg-status-info rounded-br10 p-2.5 gap-2.5', className)}>
      <InfoCircleSvg width={48} height={24} className={'mt-0.5'} />
      <p>{children}</p>
    </div>
  );
};
