import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { PageSubtitle } from '../Style/Style';
import AgencyInformation from './tabs/AgencyInformation';
import PaymentInformation from './tabs/PaymentInformation';
import MillesimeInformation from './tabs/MillesimeInformation/MillesimeInformation';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { provider, useInstances } from 'react-ioc';
import { AgenciesStore, AgencyDetailsStore } from '../Stores/Agencies.store';
import { useLocation, useNavigate } from 'react-router-dom';
import { AgencyModel } from '@assets/models/agencies/Agency.model';
import { TabBar, TabDefinition } from 'ui/TabBar';
import { PageBlock } from '../ui/layout/PageBlock';
import { Spinner } from 'ui/Spinner';
import { SectionWrapper } from '../ui/layout/SectionWrapper';
import OrderInformation from './tabs/OrderInformation';
import RestitutionComponent from './tabs/RestitutionComponent';

type LocalStore = [AgenciesStore, AgencyDetailsStore];

const tabs: TabDefinition[] = [
  {
    label: `Fiche d'identité de l'entreprise`,
    path: '/agency/information',
    Component: AgencyInformation,
    isVisible: () => true
  },
  {
    label: 'Contrat',
    path: '/agency/contract',
    Component: OrderInformation,
    isVisible: () => true
  },
  {
    label: 'Paiement',
    path: '/agency/payment',
    Component: PaymentInformation,
    isVisible: (agency?: AgencyModel) => agency?.isManagedPaymentMode
  },
  {
    label: 'Restitution',
    path: '/agency/restitution',
    Component: RestitutionComponent,
    isVisible: (agency?: AgencyModel) => agency?.isManagedPaymentMode
  },
  {
    label: 'fin de millésime',
    path: '/agency/millesime',
    Component: MillesimeInformation,
    isVisible: (agency?: AgencyModel) => agency?.isManagedPaymentMode
  }
];

const AgencyPage: FunctionComponent = provider(
  AgencyDetailsStore,
)(observer(() => {
  const [
    agenciesStore,
    agencyDetailsStore,
  ]: [AgenciesStore, AgencyDetailsStore] = useInstances<LocalStore>(AgenciesStore, AgencyDetailsStore);
  const { t } = useTranslation('agency');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (agenciesStore.currentAgency) {
      agencyDetailsStore.getOneAgency(agenciesStore.currentAgency.uid);
    }
  }, [agenciesStore.currentAgency]);

  const visibleTabs: TabDefinition[] = tabs.filter(tab => tab.isVisible(agenciesStore.currentAgency));
  const activeTab: TabDefinition | null = useMemo(() => {
    if (!agenciesStore.currentAgency) {
      return null;
    }

    const tab = visibleTabs.find(tab => tab.path === location.pathname);

    return tab ?? null;
  }, [location.pathname, agenciesStore.currentAgency, visibleTabs]);

  useEffect(() => {
    if (agenciesStore.currentAgency && !activeTab) {
      navigate('/dashboard');
    }
  }, [agenciesStore.currentAgency, activeTab]);

  return (
    <PageBlock>
      <SectionWrapper>
        {agencyDetailsStore.isAgencyLoaded || !agencyDetailsStore.agencyDetails
          ? <Spinner />
          : <>
            <h1>{agencyDetailsStore.agencyDetails.agencyName}</h1>
            <PageSubtitle>
              {`${t('subtitle')} ${agencyDetailsStore.agencyDetails.agencyName}`}
            </PageSubtitle>
            <TabBar tabs={visibleTabs} aria-label={t('subtitle')}/>
          </>
        }
      </SectionWrapper>
    </PageBlock>
  );
}));

export default AgencyPage;
