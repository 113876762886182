import { MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import { useInstances } from 'react-ioc';
import { AgenciesStore } from '../../../Stores/Agencies.store';
import { DEFAULT_BENEFICIARY_CLASSIFICATION_ID } from '../../../assets/utils/agencies/beneficiaryClassification.util';
import { BeneficiaryModel } from '../../../assets/models/beneficiaries/Beneficiary.model';
import Yup from '../../../i18n/validation';
import { observer } from 'mobx-react-lite';
import { BeneficiaryClassificationModel } from '@assets/models/beneficiaries/BeneficiaryClassification.model';
import { FunctionFormComponent } from './FunctionFormComponent.model';
import { useFormLock } from '../../../Hooks/useFormLock';
import { InputField } from '../../../ui/Input';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../ui/Buttons/Button';
import { Select } from '../../../ui/Select';

export const REGISTRATION_NUMBER = 'registrationNumber';
export const EMAIL = 'email';
export const EMAIL_LOCK = 'isEmailLocked';
export const CLASSIFICATION = 'beneficiaryClassificationUid';

const timestampToDateString = (timestamp: number): string => {
  return new Date(timestamp).toLocaleDateString();
};

const AccountInformationFormComponent: FunctionFormComponent = observer((props) => {

  const { t } = useTranslation('beneficiaries');

  const {
    formik, beneficiary, isEditMode, disableModification, setFieldEnabled,
    setFieldDisabled,
  } = props;

  const [isEmailLocked, setIsEmailLocked] = useFormLock(formik, EMAIL_LOCK);

  const [
    agenciesStore,
  ]: [AgenciesStore] = useInstances<[AgenciesStore]>(AgenciesStore);
  const agencyBeneficiaryClassifications = agenciesStore.currentAgencyBeneficiaryClassification;
  const listOfClassifications = agenciesStore.currentAgency.useBeneficiaryClassification ? agencyBeneficiaryClassifications : [];

  useEffect(() => {
    if (isEmailLocked) {
      setFieldDisabled?.(EMAIL);
    } else {
      setFieldEnabled?.(EMAIL);
    }
  }, [isEmailLocked]);

  useEffect(() => {
    if (!beneficiary?.beneficiaryClassificationUid) {
      const baseClassifications: BeneficiaryClassificationModel[] = agenciesStore.currentAgencyBeneficiaryClassification
        ? [...agenciesStore.currentAgencyBeneficiaryClassification]
        : [];
      const sortedClassifications: BeneficiaryClassificationModel[] = baseClassifications
        .sort((a, b) => (a.beneficiaryCount ?? 0) - (b.beneficiaryCount ?? 0));

      const [defaultClassification] = sortedClassifications;
      const defaultUid: string = defaultClassification?.uid ?? '';

      formik.setFieldValue(CLASSIFICATION, defaultUid);
    }
  }, [beneficiary, agenciesStore.currentAgencyBeneficiaryClassification]);

  return (
    <div className={'space-y-2'}>
      <h3 className={'font-bold'}>
        Informations sur le compte
      </h3>
      {(beneficiary?.deactivationDate) &&
          <p className={'text-status-error'}>
              Ce collaborateur est sorti du dispositif le : {timestampToDateString(beneficiary.deactivationDate)}
          </p>
      }

      <div className={'w-1/2'}>
        <InputField
          id={REGISTRATION_NUMBER}
          name={REGISTRATION_NUMBER}
          label={t(REGISTRATION_NUMBER)}
          placeholder={t(`${REGISTRATION_NUMBER}Placeholder`)}
          required
          fullWidth
          formik={formik}
          autoFocus
          disabled={isEditMode && !beneficiary?.isActive || disableModification}
        />
      </div>
      <div className={'flex items-center gap-4'}>
        <div className={'w-1/2'}>
          <InputField
            id={EMAIL}
            name={EMAIL}
            label={t(EMAIL)}
            placeholder={t(`${EMAIL}Placeholder`)}
            value={formik.values[EMAIL]}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(EMAIL, evt.target.value)}
            required
            fullWidth
            error={formik.touched[EMAIL] && Boolean(formik.errors[EMAIL])}
            errorMessage={formik.touched[EMAIL] && formik.errors[EMAIL] as string}
            disabled={isEditMode && isEmailLocked || disableModification}
          />
        </div>
        {isEditMode && isEmailLocked &&
            <Button className={'h-fit'} onClick={() => setIsEmailLocked(false)} disabled={disableModification}>
                Déverrouiller pour modifier
            </Button>
        }
      </div>
      {agenciesStore.currentAgency?.useBeneficiaryClassification && (
        <div className={'w-1/2'}>
          <Select
            id={CLASSIFICATION}
            name={CLASSIFICATION}
            label={t('Classification')}
            fullWidth
            value={formik.values[CLASSIFICATION]}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue(CLASSIFICATION, evt.target.value)}
            disabled={disableModification}
          >
            {
              listOfClassifications?.map((classification) =>
                <MenuItem key={classification.uid} value={classification.uid}>{classification.name}</MenuItem>,
              )
            }
          </Select>
        </div>
      )}
    </div>
  );
}) as FunctionFormComponent;

AccountInformationFormComponent.getValidationSchema = (disabledFields?: string[]) => {
  return Yup.object().shape({
    [EMAIL]: disabledFields && disabledFields.includes(EMAIL)
      ? Yup.string()
      : Yup.string().email().required(),
    [REGISTRATION_NUMBER]: Yup.string().required(),
  });
};

AccountInformationFormComponent.getInitialValues = (beneficiary?: BeneficiaryModel) => {
  return {
    [REGISTRATION_NUMBER]: beneficiary?.registrationNumber ?? '',
    [EMAIL]: beneficiary?.email ?? '',
    [EMAIL_LOCK]: true,
    [CLASSIFICATION]: beneficiary?.beneficiaryClassificationUid ?? DEFAULT_BENEFICIARY_CLASSIFICATION_ID,
  };
};

export default AccountInformationFormComponent;