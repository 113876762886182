/* eslint-disable max-len */
import React from 'react';
import { COLORS } from '../Style/Style';

interface SvgProps extends React.SVGProps<SVGSVGElement> {
  color?: string;
}

const Lock = ({ color = COLORS.black, ...rest }: SvgProps) => (
  <svg width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M15.636 7.31707H14.1471V5.36585C14.1471 2.40237 11.7027 0 8.6875 0C5.67227 0 3.22794 2.40237 3.22794 5.36585V7.31707H1.73897C0.916635 7.31707 0.25 7.97227 0.25 8.78049V18.5366C0.25 19.3448 0.916635 20 1.73897 20H15.636C16.4584 20 17.125 19.3448 17.125 18.5366V8.78049C17.125 7.97227 16.4584 7.31707 15.636 7.31707ZM4.71691 5.36585C4.71691 3.2106 6.4946 1.46341 8.6875 1.46341C10.8804 1.46341 12.6581 3.2106 12.6581 5.36585V7.31707H4.71691V5.36585ZM15.636 18.5366H1.73897V8.78049H15.636V18.5366Z"
          fill={color}/>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M8.9356 16.0975C10.1691 16.0975 11.1691 15.1147 11.1691 13.9024C11.1691 12.6901 10.1691 11.7073 8.9356 11.7073C7.7021 11.7073 6.70215 12.6901 6.70215 13.9024C6.70488 15.1136 7.70323 16.0948 8.9356 16.0975ZM8.9356 13.1707C9.34677 13.1707 9.68009 13.4983 9.68009 13.9024C9.68009 14.3065 9.34677 14.6341 8.9356 14.6341C8.52444 14.6341 8.19112 14.3065 8.19112 13.9024C8.19112 13.4983 8.52444 13.1707 8.9356 13.1707Z"
          fill={color}/>
  </svg>

);

export default Lock;
