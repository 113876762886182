import React, { useCallback, useState } from 'react';
import {
  DataGrid as MuiDataGrid,
  DataGridProps,
  GridPagination,
  GridRowSpacingParams,
  GridToolbarContainer, GridLocaleText,
} from '@mui/x-data-grid';
import { SxProps, Theme } from '@mui/material';
import { Spinner } from './Spinner';
import { GridPaginationModel } from '@mui/x-data-grid/models/gridPaginationProps';

interface CustomDataGridProps extends DataGridProps {
  idKey?: string;
  showHeader?: boolean;
}

export const DEFAULT_PAGE_SIZE = 25;
export const DataSpreadsheet: React.FC<CustomDataGridProps> = (props) => {
  const {
    rows = [],
    columns,
    rowCount,
    loading,
    paginationModel: propsPaginationModel, // when paginationMode = "server"
    onPaginationModelChange, // when paginationMode = "server"
    onSortModelChange,
    getRowId,
    showHeader,
    paginationMode = 'client',
    sortingMode = 'client',
    ...rest
  } = props;

  const _rowCount = rowCount ?? rows?.length ?? 0;
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  });
  const getRowSpacing = useCallback((params: GridRowSpacingParams) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridPagination/>
      </GridToolbarContainer>
    );
  };

  const CustomLoader = () => {
    return (
      <div className={'flex justify-center'}>
        <Spinner/>
      </div>
    );
  };

  const localeTextFR: Partial<GridLocaleText> = {
    noRowsLabel: loading || rows.length === 0 ? '' : 'Pas de résultats',
    MuiTablePagination: {
      labelDisplayedRows: ({ from, to, count }) => {
        const validFrom = isNaN(from) ? 0 : from;
        const validTo = isNaN(to) ? 0 : to;
        const validCount = count !== undefined ? count.toLocaleString() : '-';
        return `${validCount.length > 1 ? 'Lignes' : 'Ligne'} ${validFrom} à ${validTo} sur ${validCount}`;
      },
    },
  };

  return (
    <div className="relative flex w-full h-auto transition-height duration-100">
      <MuiDataGrid
        rows={rows}
        columns={columns}
        rowCount={_rowCount}
        loading={loading}
        paginationMode={paginationMode}
        sortingMode={sortingMode}
        autoHeight
        getRowSpacing={getRowSpacing}
        paginationModel={propsPaginationModel ?? paginationModel}
        onPaginationModelChange={onPaginationModelChange ?? setPaginationModel}
        onSortModelChange={onSortModelChange}
        localeText={localeTextFR}
        slots={{
          toolbar: showHeader && CustomToolbar,
          loadingOverlay: CustomLoader,
        }}
        getRowId={(row) => props.idKey ? row[props.idKey] : row.uid || row.id}
        sx={styles}
        {...rest}
      />
    </div>
  );
};

const styles: SxProps<Theme> = {
  border: 'none',
  minHeight: '400px',
  paddingBottom: '2rem',
  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-row': {
    backgroundColor: 'var(--color-background-DEFAULT)',
    borderRadius: '30px',
    transition: 'border-color 0.3s ease-in-out',
    paddingLeft: '.5rem',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid var(--color-muted)',
      cursor: 'pointer',
    },
  },
  '& .MuiDataGrid-cell': {
    border: 'none',
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft': {
    border: 'none',
  },
  '& .MuiDataGrid-colCellTitle': {
    color: 'var(--color-primary)',
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-colCell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
    border: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
    border: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none !important',
  },
  '& .MuiDataGrid-columnsContainer': {
    border: 'none !important',
  },
  '& .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor': {
    border: 'none',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 700,
    },
  },
  '& .MuiDataGrid-row.Mui-selected': {
    backgroundColor: 'var(--color-green-400)',
  },
  '& .MuiDataGrid-row.Mui-selected:hover': {
    backgroundColor: 'var(--color-green-400)',
  },
  '& .MuiDataGrid-withBorderColor': {
    border: 'none',
  },
  '& .MuiDataGrid-footerContainer': {
    border: 'none',
    justifyContent: 'flex-start',
  },
  '& .MuiSvgIcon-root': {
    color: 'var(--color-primary)',
  },
  '& .MuiDataGrid-renderingZone': {
    maxHeight: '100% !important',
  },
  '& .MuiIconButton-root.Mui-disabled': {
    visibility: 'hidden',
  },
  '& .MuiSelect-select.MuiSelect-select': {
    paddingRight: '36px',
  },
  '& .MuiTablePagination-spacer': {
    display: 'none !important',
  },
  '& .MuiDataGrid-filler': {
    display: 'none !important',
  },
  '& .MuiInputBase-root.MuiDataGrid-editInputCell': {
    borderRadius: '100px',
    background: 'white',
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
    background: 'transparent !important',
    outline: 'none !important',
    boxShadow: 'none !important',
    padding: '6px 10px !important',
  },
};
