import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import trash from '../../../assets/trash.png';
import accept from '../../../assets/accept.png';
import cancel from '../../../assets/cancel.png';
import React from 'react';
import { EditableGridMonthYear } from '../../../Component/DatePicker/EditableGridMonthYear';
import { Button } from '../../../ui/Buttons/Button';

interface DataGridColumnsProps {
  handleCancelAdd: (event: React.MouseEvent) => void;
  handleSaveNewRow: (params: any) => void;
  handleRemoveRow: (params: any) => void;
  uniqueId: string | null;
  dateOptions: Intl.DateTimeFormatOptions;
  disabled: boolean,
}

export const getDataGridColumn = ({
  handleCancelAdd,
  handleSaveNewRow,
  handleRemoveRow,
  uniqueId,
  dateOptions,
  disabled,
}: DataGridColumnsProps): GridColDef[] => {

  return [
    {
      field: 'startDate',
      headerName: 'Début',
      flex: 2,
      type: 'date',
      valueFormatter: (value: unknown):string => value instanceof Date ? value.toLocaleDateString('fr-FR', dateOptions) : '',
      renderEditCell: EditableGridMonthYear,
      editable: true,
      valueSetter: (value, row) => {
        if (!(value instanceof Date)) {
          return row;
        }

        row.startDate = value;

        return row;
      }
    },
    {
      field: 'nbOfMonth',
      headerName: 'Nb de mois',
      flex: 2,
      type: 'custom',
      editable: true,
      valueParser: intStringValueParser,
      valueSetter: (value: number, row) => {
        if (value != row.nbOfMonth){
          row.nbOfMonth = value;
          row.totalOfMealReduction = value * row.nbOfMealReductionPerMonth;
        }
        return row;
      }
    },
    {
      field: 'nbOfMealReductionPerMonth',
      headerName: 'Nb repas en moins / mois',
      flex: 3,
      type: 'custom',
      editable: true,
      valueParser: intStringValueParser,
      valueSetter: (value: number, row) => {
        if (value != row.nbOfMealReductionPerMonth){
          row.nbOfMealReductionPerMonth = value;
          row.totalOfMealReduction = row.nbOfMonth * value;
        }
        return row
      }
    },
    {
      field: 'totalOfMealReduction',
      headerName: 'Total de repas en moins',
      flex: 3,
      type: 'custom',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: '',
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const edit: boolean = params.id === uniqueId;
        return edit
          ? (
            <div className={'flex w-100'}>
              <Button
                variant={'text'}
                size={'small'}
                onClick={handleCancelAdd}
                disabled={disabled}
              >
                <img src={cancel} alt={'icon annuler'} width={24} height={24}/>
              </Button>
              <Button
                variant={'text'}
                size={'small'}
                onClick={() => handleSaveNewRow(params)}
                disabled={disabled}
              >
                <img src={accept} alt={'icon valider'} width={24} height={24}/>
              </Button>
            </div>)
          : (<div className={'flex w-100 justify-end'}>
            <Button
              variant={'text'}
              onClick={() => handleRemoveRow(params)}
              disabled={false}
            >
              <img src={trash} alt={'icon supprimer'} width={24} height={24}/>
            </Button>
          </div>);
      },
    },
  ];
};

function intStringValueParser(value: string): number {
  const valueNum = Number(value);
  if (isNaN(valueNum)) {
    const acceptableString = value.replace(/\D/g, '');
    const replacedValue = Number(acceptableString);
    return isNaN(replacedValue) ? 0 : replacedValue;
  }

  return valueNum;
}