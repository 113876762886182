import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { Button } from '../../../../ui/Buttons/Button';
import { Spinner } from '../../../../ui/Spinner';
import { InfoBox } from '../../../../ui/InfoBox';
import { MandateStatus } from '@assets/models/mandates/mandate.model';
import { AgenciesStore } from '../../../../Stores/Agencies.store';
import { MandateStore } from '../../../../Stores/Mandate.store';
import { OrderPaymentViewStore } from '../../../../Stores/viewStore/OrderPaymentView.store';
import { StepperRechargingStore } from '../../../../Stores/viewStore/StepperRecharging.store';
import { AgencyInvoiceStore } from '../../../../Stores/AgencyInvoice.store';

type LocalStore = [
  OrderPaymentViewStore,
  StepperRechargingStore,
  MandateStore,
  AgenciesStore,
  AgencyInvoiceStore,
];

const MethodDirectDebit = observer(() => {
  const [
    orderPaymentViewStore,
    stepperRechargingStore,
    mandateStore,
    agenciesStore,
    agencyInvoiceStore,
  ]: LocalStore = useInstances<LocalStore>(
    OrderPaymentViewStore,
    StepperRechargingStore,
    MandateStore,
    AgenciesStore,
    AgencyInvoiceStore,
  );
  const { t } = useTranslation(['payment', 'common']);

  const mandateStatus: MandateStatus = mandateStore.mandateStatus;
  const mandateSignedLoading: boolean = mandateStore.isFindingMandate;
  const totalTTC: string = orderPaymentViewStore.totalTTC;
  const today: string = new Date().toLocaleDateString();
  const iban: string = agenciesStore.currentAgency.iban;
  const isOrderListEmpty: Boolean = agencyInvoiceStore.invoices.length === 0;

  return (
    <>
      {mandateSignedLoading
        ? <Spinner/>
        : <div className={'bg-background rounded-br10 p-4 space-y-6'}>
          {mandateStatus === 'ACTIVE' &&
            <>
              <h2 className={'m-0 text-base'}>
                {t('directDebit.title', { amount: totalTTC })}
              </h2>
              <div className={'space-y-2'}>
                <p>{t('directDebit.titleAccount')}</p>
                <p className={'font-bold'}>
                  {t('directDebit.accountNumber', { accountNumber: iban })}
                </p>
              </div>
              <div className={'space-y-2'}>
                <p>{t('directDebit.titleDateDebit')}</p>
                <p className={'font-bold'}>
                  {t('directDebit.dateDebit', { date: today })}
                </p>
              </div>
            </>
          }
          {mandateStatus !== 'ACTIVE' &&
            <>
              <h2 className={'m-0 text-base'}>
                {t('directDebit.noteMandate.title')}
              </h2>
              {isOrderListEmpty ?
                <p>
                  {t('directDebit.noteMandate.text')}
                </p>
                :
                <InfoBox>
                  <p>
                    {t('directDebit.noteMandate.textIbanChanged')}
                    <a className={'text-primary text-base font-normal no-underline hover:underline'}
                       href={'mailto:contact-pro@stayopen.io'}>
                      contact-pro@stayopen.io
                    </a><br/><br/>
                    {t('signature', { ns: 'common' })}
                  </p>
                </InfoBox>
              }

              <div className={'flex justify-end gap-4'}>
                <Button
                  // disabled={payingServiceStore.createPayingLoading}
                  // isFetching={payingServiceStore.createPayingLoading}
                  onClick={() => stepperRechargingStore.goToPreviousStep()}
                >
                  {t('directDebit.noteMandate.btnCancel')}
                </Button>
                <Button
                  // disabled={payingServiceStore.createPayingLoading}
                  // isFetching={payingServiceStore.createPayingLoading}
                  onClick={() => orderPaymentViewStore.setOpenMandateDialog(true)}
                >
                  {t('directDebit.noteMandate.btnValid')}
                </Button>
              </div>
            </>
          }
        </div>}
    </>
  );
});

export default MethodDirectDebit;
