import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInstances } from 'react-ioc';
import { AgencyModel } from '../../assets/models/agencies/Agency.model';
import { AgenciesStore, AgencyDetailsStore } from '../../Stores/Agencies.store';
import { MandateStore } from '../../Stores/Mandate.store';
import { obfuscateIbanNumbersUtil } from '../../assets/utils/databank/obfuscateIbanNumbers.util';
import { InputField } from '../../ui/Input';
import { Button } from '../../ui/Buttons/Button';
import { useFormik } from 'formik';
import Yup from '../../i18n/validation';
import { Select } from 'ui/Select';
import { MenuItem } from '@mui/material';
import country from '../../i18n/fr/country';
import isValidIBANNumber from '../../Function/IbanValidator';
import Lock from '../../assets/lock';
import { UpdateAgencyPaymentRequest } from '@assets/requests/agencies/UpdateAgencyPayment.request';
import { Toaster } from '../../ui/Toaster';
import { InfoBox } from '../../ui/InfoBox';
import { AgencyInvoiceStore } from '../../Stores/AgencyInvoice.store';

const PaymentInformationComponent: FunctionComponent = observer(() => {
  const [mandateStore, agenciesDetailsStore, agenciesStore, agencyInvoiceStore]: [MandateStore, AgencyDetailsStore, AgenciesStore, AgencyInvoiceStore] = useInstances<[MandateStore, AgencyDetailsStore, AgenciesStore, AgencyInvoiceStore]>(MandateStore, AgencyDetailsStore, AgenciesStore, AgencyInvoiceStore);
  const currentAgency: AgencyModel = agenciesDetailsStore.agencyDetails!;
  const { t } = useTranslation(['agency', 'country']);
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();

  useEffect(() => {
    if (currentAgency.paymentMethod !== 'DIRECT_DEBIT') {
      void agenciesStore.getAgencyBankAccountAlias();
    }
    if (currentAgency.paymentMethod === 'DIRECT_DEBIT') {
      void mandateStore.findMandateByAgencyId(currentAgency.uid);
    }
  }, [currentAgency]);

  const getFlag: (iban: string) => string = useCallback((iban: string) => {
    const sanitizedIban: string = iban.trim().toUpperCase().replace(/[^A-Z0-9]/g, '');
    if (!sanitizedIban || sanitizedIban.length < 2) {
      return '';
    }
    const countryCode: string = iban.slice(0, 2);
    return countryCode.toLowerCase();
  }, []);

  Yup.addMethod(Yup.string, 'isValidIBANNumber', isValidIBANNumber);

  const validationSchema = Yup.object().shape({
    iban: Yup.string().required()['isValidIBANNumber'](),
    paymentBankBIC: Yup.string().required(),
    paymentBankTitular: Yup.string().required(),
    paymentBankAddress: Yup.string().required(),
    paymentBankZipCode: Yup.number().required(),
    paymentBankCountryCode: Yup.string().required(),
    paymentBankAdditionalAddress: Yup.string(),
    paymentBankCity: Yup.string().required(),
  });

  const {
    iban = '',
    paymentBankBIC = '',
    paymentBankTitular = '',
    paymentBankAddress = '',
    paymentBankZipCode = '',
    paymentBankCountryCode = 'FR',
    paymentBankAdditionalAddress = '',
    paymentBankCity = '',
    paymentMethod,
    agencyName,
  } = currentAgency;

  const formik = useFormik({
    initialValues: {
      iban: obfuscateIbanNumbersUtil(iban),
      paymentBankBIC,
      paymentBankTitular,
      paymentBankAddress,
      paymentBankZipCode,
      paymentBankCountryCode,
      paymentBankAdditionalAddress,
      paymentBankCity,
    },
    enableReinitialize: true,
    validationSchema,
    validateOnBlur: true,
    onSubmit: async (request: UpdateAgencyPaymentRequest): Promise<void> => {
      try {
        await agenciesStore.updateAgencyPayment({ ...request, paymentMethod, agencyName });
        agenciesDetailsStore.updateAgencyDetails(request);
        setSuccessMessage('Les informations de paiement ont bien été modifiées');
        setIsDisabled(true);
      } catch (error) {
        setErrorMessage('Il semble qu\'il y ait eu une erreur...');
      }
    },
  });

  return (
    <>
      <h3 className={'font-bold text-base pt-5 pl-5'}>{t('paymentTitle')}</h3>
      {mandateStore.mandate && isDisabled && (
        <a
          href={mandateStore.mandate.documentUrl}
          target="_blank" rel="noreferrer" className={'no-underline'}>
          <Button color="primary">
            {t('seeMandate')}
          </Button>
          <br/>
        </a>
      )}

      <section className={'flex flex-col md:flex-row gap-4 pb-2'}>
        {currentAgency.paymentMethod === 'DISSOCIATED_BANK_TRANSFER' && (
          <>
            <InputField
              label={t('ibanSubscriptionDissociatedMain')}
              loading={agenciesStore.isBankAccountsLoading}
              fullWidth
              value={!agenciesStore.isBankAccountsLoading
                ? agenciesStore.paymentBankAccountMain?.iban || t('notSpecified')
                : ''
              }
              disabled
            />
            <InputField
              label={t('ibanSubscriptionDissociatedFees')}
              loading={agenciesStore.isBankAccountsLoading}
              fullWidth
              value={!agenciesStore.isBankAccountsLoading
                ? agenciesStore.paymentBankAccountFees?.iban || t('notSpecified')
                : ''
              }
              disabled
            />
          </>

        )}
      </section>
      {currentAgency.paymentMethod === 'DIRECT_DEBIT' && (
        <div className={'flex flex-col gap-2 pb-2'}>
          <section className={'flex flex-col lg:flex-row gap-4'}>
            <InputField
              label={t('ibanSubscription')}
              id="iban"
              name="iban"
              formik={formik}
              fullWidth
              autoFocus
              countryFlag={getFlag(formik.values.iban)}
              required
              disabled={isDisabled}
              error={Boolean(formik.errors['iban']) && formik.touched['iban']}
              errorMessage={formik.errors['iban']}
            />

            {isDisabled ?
              <div className={'w-full flex'}>
                <Button
                  endIcon={<Lock color={'white'}/>}
                  className={'h-[50px] w-full lg:w-[50%] self-center'}
                  onClick={() => {
                    formik.setFieldValue('iban', '');
                    formik.setFieldValue('paymentBankBIC', '');
                    setIsDisabled(false);
                  }}>
                  Déverouiller pour modifier
                </Button></div> :
              <InputField
                label={t('bic')}
                id="paymentBankBIC"
                name="paymentBankBIC"
                formik={formik}
                fullWidth
                required
                disabled={isDisabled}
                error={Boolean(formik.errors['paymentBankBIC']) && formik.touched['paymentBankBIC']}
                errorMessage={formik.errors['paymentBankBIC']}
              />}</section>

          {mandateStore.mandateStatus !== 'ACTIVE' && agencyInvoiceStore.invoices.length > 0 && isDisabled &&
              <InfoBox className={'bg-status-light py-4 w-full lg:w-[75%]'}>
                  Vous avez modifié votre IBAN,
                  un nouveau mandat de prélèvement sera à signer lors de votre prochaine commande
              </InfoBox>}

        </div>
      )}

      <section className={'flex flex-col md:flex-row gap-4 pb-2'}>
        <InputField
          label={t('holderFullName')}
          fullWidth
          disabled={isDisabled}
          id="paymentBankTitular"
          formik={formik}
          required
          error={Boolean(formik.errors['paymentBankTitular']) && formik.touched['paymentBankTitular']}
          errorMessage={formik.errors['paymentBankTitular']}
        />
        <div className={'hidden md:flex w-full'}/>
      </section>
      <section className={'flex flex-col md:flex-row gap-4'}>
        {currentAgency.paymentMethod === 'BANK_TRANSFER' && (
          <InputField
            label={t('ibanSubscriptionBankTransfer')}
            loading={agenciesStore.isBankAccountsLoading}
            fullWidth
            value={!agenciesStore.isBankAccountsLoading
              ? agenciesStore.paymentBankAccountMain?.iban || t('notSpecified')
              : ''
            }
            disabled
          />
        )}
      </section>

      <section className={'flex flex-col md:flex-row gap-4'}>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('address')}
            fullWidth
            disabled={isDisabled}
            id="paymentBankAddress"
            formik={formik}
            required
            error={Boolean(formik.errors['paymentBankAddress']) && formik.touched['paymentBankAddress']}
            errorMessage={formik.errors['paymentBankAddress']}
          />
          <InputField
            label={t('zipcode')}
            fullWidth
            disabled={isDisabled}
            id="paymentBankZipCode"
            formik={formik}
            required
            error={Boolean(formik.errors['paymentBankZipCode']) && formik.touched['paymentBankZipCode']}
            errorMessage={formik.errors['paymentBankZipCode']}
          />
          <Select
            fullWidth
            hideIcon={isDisabled}
            className={isDisabled ? 'bg-white border-background-block hover:border-background-block' : ''}
            value={formik.values.paymentBankCountryCode}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) => formik.setFieldValue('paymentBankCountryCode', evt.target.value)}
            id="paymentBankCountryCode"
            disabled={isDisabled}
            label={t('country')}
            required
          >
            {
              Object.entries(country).map(([countryCode, countryName]) =>
                <MenuItem key={countryCode} value={countryCode}>{countryName}</MenuItem>,
              )
            }
          </Select>
        </div>
        <div className={'flex-1 space-y-2'}>
          <InputField
            label={t('additionalAddress1')}
            placeholder={t(`notSpecified`)}
            fullWidth
            disabled={isDisabled}
            id="paymentBankAdditionalAddress"
            formik={formik}
            error={Boolean(formik.errors['paymentBankAdditionalAddress']) && formik.touched['paymentBankAdditionalAddress']}
            errorMessage={formik.errors['paymentBankAdditionalAddress']}
          />
          <InputField
            label={t('city')}
            fullWidth
            disabled={isDisabled}
            id="paymentBankCity"
            formik={formik}
            required
            error={Boolean(formik.errors['paymentBankCity']) && formik.touched['paymentBankCity']}
            errorMessage={formik.errors['paymentBankCity']}
          />
        </div>
      </section>
      {
        !isDisabled && <
              div className={'flex flex-col md:flex-row gap-2 mt-8 justify-end'}>
              <Button onClick={() => {
                setIsDisabled(true);
                formik.resetForm();
              }}>
                  Annuler
              </Button>
              <Button
                  loading={formik.isSubmitting}
                  disabled={!formik.isValid}
                  onClick={() => formik.handleSubmit()}>
                  Valider
              </Button>
          </div>
      }

      <Toaster variant={'error'} open={!!errorMessage} onClose={() => setErrorMessage('')}>
        <>{errorMessage}</>
      </Toaster>
      <Toaster variant={'success'} open={!!successMessage} onClose={() => setSuccessMessage('')}>
        <>{successMessage}</>
      </Toaster>
    </>
  );

});

export default PaymentInformationComponent;
