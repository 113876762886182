const payment = {
  directDebit: {
    title: 'Cette commande sera payée par prélèvement.',
    titleAccount: 'Sur le compte :',
    accountNumber: 'numéro {{accountNumber}}',
    titleDateDebit: 'Date de prélèvement :',
    dateDebit: 'le {{date}}',
    noteMandate: {
      title: 'Cette commande sera payée par prélèvement.',
      text: 'C\'est la première fois que vous utilisez ce mode de règlement, nous allons vous rediriger vers notre\n ' +
        'partenaire pour signer votre mandat de prélèvement. Cette étape n\'est à faire qu\'une seule fois.',
      textIbanChanged: 'Attention, votre IBAN a été modifié depuis votre dernière commande, vous devez signer un nouveau mandat de prélèvement.\n\n' +
        'Si vous n\'êtes pas à l’origine de cette demande de modification, veuillez contacter Openeat via notre chat ou par mail à ',
      btnCancel: 'Annuler',
      btnValid: 'Ok, signer le mandat de prélèvement',
    },
    btnSubmit: 'Payer ma commande',
  },
  bankTransfer: {
    title: 'Cette commande sera payée par virement.',
    subTitle: 'Voici les références bancaires à utiliser lors de votre virement :',
    ibanTitle: 'IBAN de votre compte Open!',
    iban: '{{iban}}',
    note: 'À noter : N’oubliez pas d’indiquer, dans le libellé de votre virement, la référence de la commande\n ' +
      'que vous trouverez dans l étape suivante ainsi que dans le mail de confirmation.',
    btnSubmit: 'Valider ma commande',
  },
  dissociatedBankTransfer: {
    mainTitle: 'Les provisions seront payées par virement sur un compte dédié.',
    mainLabel: 'Compte pour le paiement de la provision',
    feesTitle: 'Les frais d\'abonnement seront payés par virement sur un autre compte dédié.',
    feesLabel: 'Compte pour le paiement de l’abonnement'
  },
  orderDetails: {
    title: 'Titres restaurant : Abonnement pour {{count}} collaborateurs pour {{month}} ',
    subscriptionTitle: 'Frais d\'abonnement',
    subscription_one: '{{count}} collaborateur x abonnement de {{subComp}}',
    subscription_other: '{{count}} collaborateurs x abonnement de {{subComp}}',
    subscriptionMultiClass_one: '{{count}} collaborateur',
    subscriptionMultiClass_other: '{{count}} collaborateurs',
    ht: 'Total HT',
    ttc: 'Total abonnements',
    subtitle: 'Abondement de la participation employeur pour {{month}}',
    provisionTitle: 'Provision basée sur le nombre de droits rechargés',
    provision: '{{count}} jours travaillés x part employeur de {{subComp}}',
    totalTitle: 'Total Abonnements TTC + Participation employeur',
    total: 'TOTAL',
  },
  success: {
    felicitations: 'Félicitations !',
    paymentDescription: 'Votre commande d\'un montant de {{total}} € a bien été prise en compte.',
    paymentDescription2: 'Vous allez recevoir un email de confirmation.',
    btnSubmit: 'Ok, c\'est noté !',
    bankTransferDescription: 'Pour simplifier la gestion de votre compte, merci de renseigner cette référence lors de votre virement :',
    dissociatedBankTransferDescription: 'Pour simplifier la gestion de votre compte, merci de renseigner cette référence',
    dissociatedBankTransferDescriptionTransfer: 'lors du virement',
    dissociatedBankTransferDescriptionAnd: 'et',
    dissociatedBankTransferDescriptionFees: 'de l\'abonnement',
    dissociatedBankTransferDescriptionMain: 'de la provision',
  },
};

export default payment;
