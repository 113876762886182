import {
  GridCellModes,
  GridCellModesModel,
  GridColDef,
  GridRowEditStartParams,
  GridRowEditStopParams,
  GridRowModel,
  MuiEvent,
} from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { BeneficiaryModel } from '../../../../../../lambdas/shared/models/beneficiaries/Beneficiary.model';
import { onCall } from '../../../Services/firebase.service';
import {
  GetBeneficiaryVoucherRestrictionRequest,
} from '@assets/requests/discreteVouchers/GetBeneficiaryVoucherRestrictionRequest';
import { getEndDateFromNbOfMonths, getNbOfMonthsFormRestrictionPeriod } from '../../../Utils/voucherDateMapper';
import { VoucherRestrictionModel } from '../../../assets/models/discreteVouchers/VoucherRestriction.model';
import { AddVoucherRestrictionRequest } from '../../../assets/requests/discreteVouchers/AddVoucherRestrictionRequest';
import {
  DeleteVoucherRestrictionRequest,
} from '../../../assets/requests/discreteVouchers/DeleteVoucherRestrictionRequest';
import { getDataGridColumn } from './DataGridBeneficiaryActivity';
import { Button } from '../../../ui/Buttons/Button';
import { DataSpreadsheet } from '../../../ui/DataSpreadsheet';
import { DeleteVoucherConfirmationDialog } from 'Component/Dialog/DeleteVoucherConfirmationDialog';

export interface DataRowItem {
  id: string;
  startDate: Date;
  nbOfMonth: number;
  nbOfMealReductionPerMonth: number;
  totalOfMealReduction: number;
}

export interface ErrorsRow {
  startDate: string | null;
  nbOfMonth: string | null;
  nbOfMealReductionPerMonth: string | null;
}

interface Props {
  beneficiary: BeneficiaryModel;
}

export const RightsReductionPeriod: ({ beneficiary }: Props) => React.JSX.Element = ({ beneficiary }: Props) => {

  const [isServerError, setIsServerError] = useState<boolean>(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
  const [deleteVoucherRestrictionRequest, setDeleteVoucherRestrictionRequest] = useState<DeleteVoucherRestrictionRequest | null>(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorsRow | null>();

  const [existingMonthlyUsageReductions, setExistingMonthlyUsageReductions] = useState<VoucherRestrictionModel[]>([]);
  const [rows, setRows] = useState<DataRowItem[]>([]);
  const [newRow, setNewRow] = useState<DataRowItem | null>(null);
  const [uniqueId, setUniqueId] = useState<string | null>(null);
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const now: Date = new Date();
  const defaultStartDate: Date = new Date(now.getFullYear(), now.getMonth(), 1);
  const maxUsageReduction = beneficiary?.monthlyVoucherUsageLimit ?? 0;

  const isOverlapDetected: (newRow: DataRowItem) => boolean = useCallback((newRow: DataRowItem) => {
    const newRestrictionStartMillis = newRow.startDate.getTime();
    const newRestrictionEndMillis = getEndDateFromNbOfMonths(newRestrictionStartMillis, newRow.nbOfMonth).getTime();

    if (newRestrictionStartMillis < defaultStartDate.getTime()) {
      return true;
    }

    if (!existingMonthlyUsageReductions.length) {
      return false;
    }

    return existingMonthlyUsageReductions.some((existingRestriction) => {
      const existingRestrictionStartMillis = existingRestriction.startDate;
      const existingRestrictionEndMillis = existingRestriction.endDate;

      return newRestrictionStartMillis <= existingRestrictionEndMillis && newRestrictionEndMillis >= existingRestrictionStartMillis;
    });
  }, [existingMonthlyUsageReductions]);

  const validateRow: (newRow: DataRowItem) => boolean = useCallback((newRow: DataRowItem) => {
    setErrors(null);

    const errors: ErrorsRow = {
      startDate: null,
      nbOfMonth: null,
      nbOfMealReductionPerMonth: null,
    };

    const startDateValid = !isOverlapDetected(newRow);
    const nbOfMealReductionPerMonthValid = newRow.nbOfMealReductionPerMonth % 1 === 0
      && newRow.nbOfMealReductionPerMonth > 0
      && newRow.nbOfMealReductionPerMonth <= maxUsageReduction;
    const nbOfMonthValid = newRow.nbOfMonth % 1 === 0
      && newRow.nbOfMonth > 0;

    errors.startDate = !startDateValid ? `La période choisie doit être distincte des périodes déjà enregistrées et ne pas être dans le passé.` : null;
    errors.nbOfMealReductionPerMonth = !nbOfMealReductionPerMonthValid ? `Le nombre de repas en moins par mois doit être compris entre ${1} et ${maxUsageReduction}.` : null;
    errors.nbOfMonth = !nbOfMonthValid ? 'Le nombre de mois est invalide.' : null;
    setErrors(errors);

    return (startDateValid && nbOfMealReductionPerMonthValid && nbOfMonthValid);
  }, [isOverlapDetected, errors]);

  const handleSaveVoucherReduction: (params) => Promise<void> = useCallback(async (params) => {
    const rowValidate: DataRowItem = { ...params.row };

    if (!validateRow(rowValidate)) {
      return;
    }

    const request: AddVoucherRestrictionRequest = {
      beneficiaryId: beneficiary.uid,
      agencyId: beneficiary.agencyId,
      contractId: beneficiary.agencyId,
      startDate: rowValidate.startDate.getTime(),
      endDate: getEndDateFromNbOfMonths(rowValidate.startDate.getTime(), rowValidate.nbOfMonth).getTime(),
      amount: Number(rowValidate.nbOfMealReductionPerMonth),
      restrictedField: 'monthlyVoucherUsageLimit',
      operator: 'SUB',
    };

    try {
      setIsServerError(false);
      setLoading(true);
      await onCall('DISCRETE_VOUCHERS-addVoucherRestriction_onCall', request);
      await getVouchersRestrictions();
      setUniqueId(null);
      setNewRow(null);
    } catch (e) {
      setIsServerError(true);
    } finally {
      setLoading(false);
    }
  }, [rows, uniqueId, validateRow]);

  const handleAddNewRow: () => void = useCallback(() => {
    const id = `new-${now.getTime()}`;

    const newEmptyRow: DataRowItem = {
      id,
      startDate: defaultStartDate,
      nbOfMonth: 0,
      nbOfMealReductionPerMonth: 0,
      totalOfMealReduction: 0,
    };

    setRows((prevState) => [newEmptyRow, ...prevState]);
    setUniqueId(id);
    setNewRow(newEmptyRow);
    setCellModesModel({
      [id]: {
        nbOfMonth: { mode: GridCellModes.Edit },
        nbOfMealReductionPerMonth: { mode: GridCellModes.Edit },
        startDate: { mode: GridCellModes.Edit },
      },
    })
  }, []);

  const handleRemoveRow: (params) => Promise<void> = useCallback(async (params) => {
    const voucherId = params.id;
    const request: DeleteVoucherRestrictionRequest = {
      beneficiaryId: beneficiary.uid,
      contractId: beneficiary.agencyId,
      uid: voucherId,
    };
    setDeleteVoucherRestrictionRequest(request);
    setOpenDeleteConfirmation(true);
  }, []);

  const removeVoucherRestriction: (request: DeleteVoucherRestrictionRequest) => Promise<void> = useCallback(async (request: DeleteVoucherRestrictionRequest) => {
    setLoading(true);
    try {
      await onCall('DISCRETE_VOUCHERS-deleteVoucherRestriction_onCall', request);
      await getVouchersRestrictions();
    } catch (e) {
      setIsServerError(true);
    } finally {
      setLoading(false);
    }
    setOpenDeleteConfirmation(false);
  }, []);

  const handleCancelAdd: (events: React.MouseEvent) => void = useCallback((events: React.MouseEvent) => {
    events.preventDefault();
    events.stopPropagation();
    setRows((prevState) => prevState.filter(row => row.id !== uniqueId));
    setUniqueId(null);
    setNewRow(null);
    setErrors(null);
  }, [rows, uniqueId]);

  const handleProcessRowUpdate: (params) => void = useCallback((params) => {
    const { id, field, props } = params;
    const updatedRows: DataRowItem[] = rows.map((row: DataRowItem) => {
      let newRow: DataRowItem = row;
      if (row.id === id) {
        if (field === 'startDate') {
          newRow.startDate = props.value || row.startDate;
        }

        if (field === 'nbOfMonth' || field === 'nbOfMealReductionPerMonth') {
          const newNbOfMonth = field === 'nbOfMonth' ? props.value : row.nbOfMonth;
          const newNbOfMealReductionPerMonth = field === 'nbOfMealReductionPerMonth' ? props.value : row.nbOfMealReductionPerMonth;

          newRow.nbOfMealReductionPerMonth = newNbOfMealReductionPerMonth;
          newRow.nbOfMonth = newNbOfMonth;
          newRow.totalOfMealReduction = newNbOfMonth * newNbOfMealReductionPerMonth;
        }
      }
      return newRow;
    });
    setRows(updatedRows);
  }, [rows]);

  const convertRestrictionsToDataRowItem: (restrictions: VoucherRestrictionModel[]) => DataRowItem[] = useCallback((restrictions: VoucherRestrictionModel[]) => {
    return restrictions
      .sort((a, b) => b.startDate - a.startDate)
      .map((restriction) => {
        const nbOfMonth = getNbOfMonthsFormRestrictionPeriod(restriction.startDate, restriction.endDate);
        return {
          id: restriction.uid,
          startDate: new Date(restriction.startDate),
          nbOfMonth: nbOfMonth,
          totalOfMealReduction: restriction.amount * nbOfMonth,
          nbOfMealReductionPerMonth: restriction.amount,
        };
      });
  }, []);

  const getVouchersRestrictions: () => Promise<void> = useCallback(async () => {
    const request: GetBeneficiaryVoucherRestrictionRequest = {
      beneficiaryId: beneficiary.uid,
      contractId: beneficiary.agencyId,
    };
    setLoading(true);
    try {
      const restrictions: VoucherRestrictionModel[] = await onCall('DISCRETE_VOUCHERS-getBeneficiaryVoucherRestriction_onCall', request);
      const monthlyUsageReductions: VoucherRestrictionModel[] = restrictions.filter(restriction => restriction.restrictedField === 'monthlyVoucherUsageLimit' && restriction.operator === 'SUB');

      setExistingMonthlyUsageReductions(monthlyUsageReductions);
      setRows(convertRestrictionsToDataRowItem(monthlyUsageReductions));
    } catch (e) {
      setIsServerError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const dataGridDef: GridColDef[] = getDataGridColumn({
    handleCancelAdd,
    handleSaveNewRow: handleSaveVoucherReduction,
    handleRemoveRow,
    uniqueId,
    dateOptions: { year: 'numeric', month: 'long' },
    disabled: loading,
  });

  useEffect(() => {
    void getVouchersRestrictions();
  }, []);

  return (
    <section className={'p-2'}>
      <div className={'flex space-between w-full'}>
        <h3 className={'font-bold flex-1'}>
          Période de diminution de droits
        </h3>
        <Button
          className={'h-fit self-center'}
          onClick={() => handleAddNewRow()}
          disabled={!!newRow}
        >
          Ajouter une période
        </Button>
      </div>
      {isServerError && (
        <span className={'text-status-error test-sm'}>Erreur serveur veuillez réessayer plus tard !</span>
      )}

      {errors &&
        Object.keys(errors).map((key, index) => (
          <p key={index} className={'text-status-error test-sm'}>{errors[key]}</p>
        ))
      }

      <DataSpreadsheet
        loading={loading}
        localeText={{ noRowsLabel: 'Pas de résultats' }}
        rows={rows}
        columns={dataGridDef}
        cellModesModel={cellModesModel}
        hideFooterPagination={true}
        isCellEditable={(params) => params.id === uniqueId}
        disableColumnMenu
        getRowClassName={(params) =>
          `MuiDataGrid-row ${params.id.toString().startsWith('new-') ? 'row-editing' : ''}`
        }
      />

      <DeleteVoucherConfirmationDialog
        close={() => setOpenDeleteConfirmation(false)}
        open={openDeleteConfirmation}
        remove={removeVoucherRestriction}
        request={deleteVoucherRestrictionRequest} loading={loading}
      />
    </section>
  );
};
