import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useInstance } from 'react-ioc';
import { AgenciesStore } from '../../Stores/Agencies.store';
import { getTotalOfAgencyParticipationAmount } from '../../assets/utils/agencies/beneficiaryClassification.util';
import { InputField } from '../../ui/Input';

const OrderInformation: FunctionComponent = observer(() => {
  const agenciesStore = useInstance<AgenciesStore>(AgenciesStore);
  const { t } = useTranslation('agency');
  const currencyFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });
  const isMultiClass: boolean = agenciesStore.currentAgency?.useBeneficiaryClassification;

  return (
    <>
      <h3 className={'font-bold text-base pt-5 pl-5'}>{t('contractRepresentative')}</h3>
      <section className={'flex flex-col md:flex-row gap-4'}>
        <InputField
          label={t('firstName')}
          value={agenciesStore.currentAgency?.contractSignatoryFirstName}
          fullWidth
          disabled
        />
        <InputField
          label={t('lastName')}
          value={agenciesStore.currentAgency?.contractSignatoryLastName}
          fullWidth
          disabled
        />
        <InputField
          label={t('email')}
          value={agenciesStore.currentAgency?.contractSignatoryEmail}
          fullWidth
          disabled
        />
      </section>

      <h3 className={'font-bold text-base pt-5 pl-5'}>{t('orderTitle')}</h3>
      <section className={'flex flex-col md:flex-row gap-4'}>
        <InputField
          label={t('startDate')}
          value={moment(agenciesStore.currentAgency?.contractStartDate)?.format('DD/MM/yyyy')}
          fullWidth
          disabled
        />
        <InputField
          label={t('isManagedPaymentMode')}
          value={agenciesStore.currentAgency?.isManagedPaymentMode ? t('allInclusive') : t('autonomous')}
          fullWidth
          disabled
        />
        <div className={'hidden md:flex w-full'}/>
      </section>

      {
        agenciesStore.currentAgencyBeneficiaryClassification?.map(classification => (
          <div className={'bg-background px-10 py-4 rounded-br10 space-y-2 mt-6'} key={classification.uid}>
            <h3 className={'font-bold text-base m-0'}>
              {isMultiClass ? classification.name : 'Modalités de l\'abonnement'}
            </h3>
            <section className={'flex flex-col xl:flex-row gap-4'}>
              <InputField
                label={t('priceAmountPerBenificiary')}
                value={
                  !isNaN(classification.contractSubscriptionFee)
                    ? t('priceAmountPerBenificiaryValue', { count: Number(classification.contractSubscriptionFee) })
                    : t('notSpecified')
                }
                fullWidth
                disabled
              />
              {
                classification.creditRedemptionMethod === 'LINEAR'
                && (
                  <InputField
                    label={t('companyQuota')}
                    fullWidth
                    value={
                      !isNaN(classification.contractAgencyPart)
                        ? currencyFormat.format(classification.contractAgencyPart)
                        : t('notSpecified')
                    }
                    disabled
                  />
                )
              }
              {
                classification.creditRedemptionMethod === 'DISCRETE'
                && (
                  <InputField
                    label={t('agencyMaximumParticipation')}
                    fullWidth
                    value={
                      !isNaN(classification.subAmount)
                        ? currencyFormat.format(getTotalOfAgencyParticipationAmount(classification, 2))
                        : t('notSpecified')
                    }
                    disabled
                  />
                )
              }
              {
                classification.creditRedemptionMethod === 'LINEAR'
                && (
                  <InputField
                    label={t('dailyAmount')}
                    value={
                      !isNaN(classification.contractFacialValue)
                        ? currencyFormat.format(classification.contractFacialValue)
                        : t('notSpecified')
                    }
                    fullWidth
                    disabled
                  />
                )
              }
              {
                classification.creditRedemptionMethod === 'DISCRETE'
                && (
                  <InputField
                    label={t('beneficiaryMinimumThreshold')}
                    fullWidth
                    value={
                      !isNaN(classification.beneficiaryMinimumThreshold)
                        ? currencyFormat.format(classification.beneficiaryMinimumThreshold)
                        : t('notSpecified')
                    }
                    disabled
                  />
                )
              }
            </section>
          </div>
        ))
      }
    </>
  );
});

export default OrderInformation;
