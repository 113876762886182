import { BusinessVouchersInvoiceModel } from '../../assets/models/accountingDocuments/BusinessAccountingDocument.model';
import { getFrenchStringMonth } from '../../assets/utils/dates/dateTime.util';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BaseCard from './BaseCard';

interface Props {
  invoice: BusinessVouchersInvoiceModel,
  onClick: () => void,
}

const VoucherInvoiceCard = ({ invoice, onClick }: Props) => {
  const { t } = useTranslation('voucherAccounting');
  const { invoiceReference, totalAgencyParticipationAmount, month, year, storagePath } = invoice;

  const businessCompanyName = {
    label: 'Commerçant',
    value: invoice.businessCompanyName,
  }

  const reference = {
    label: 'Facture n°',
    value: invoiceReference,
  }

  const date = {
    label: 'Période',
    value: `${getFrenchStringMonth(month - 1)} ${year}`,
  }

  const numberFormat: Intl.NumberFormat = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' });

  const total = {
    label: 'Montant TTC',
    value: `${numberFormat.format(totalAgencyParticipationAmount / 100)}`,
  }

  return (
    <BaseCard onClick={onClick} buttonText={t('voucherCard.seeDetail')}>
      <div>
        <p className={'text-sm'}>{businessCompanyName.label}</p>
        <p className={'font-bold'}>{businessCompanyName.value}</p>
      </div>
      <div>
        <p className={'text-sm'}>{reference.label}</p>
        <p className={'font-bold'}>{reference.value}</p>
      </div>
      <div>
        <p className={'text-sm'}>{date.label}</p>
        <p className={'font-bold capitalize'}>{date.value}</p>
      </div>
      <div>
        <p className={'text-sm'}>{total.label}</p>
        <p className={'font-bold capitalize'}>{total.value}</p>
      </div>
    </BaseCard>

  );
};

export default VoucherInvoiceCard;